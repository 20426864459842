@import 'tailwindcss/base';

@import 'tailwindcss/components';

@import 'tailwindcss/utilities';

// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {

  ion-content,
  ion-toolbar,
  ion-footer,
  ion-header {
    --background: var(--ion-color-page-background);
    --background: #ebedf0;
  }


  /** primary **/

  --ion-color-primary: #194388;
  --ion-color-primary-rgb: 25,
  67,
  136;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255,
  255,
  255;
  --ion-color-primary-shade: #163b78;
  --ion-color-primary-tint: #305694;

  /** secondary **/
  --ion-color-secondary: #E3032C;
  --ion-color-secondary-rgb: 227,
  3,
  44;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255,
  255,
  255;
  --ion-color-secondary-shade: #c80327;
  --ion-color-secondary-tint: #e61c41;

  /** tertiary **/

  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82,
  96,
  255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255,
  255,
  255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/

  --ion-color-success: #00a75a;
  --ion-color-success-rgb: 0,
  167,
  90;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255,
  255,
  255;
  --ion-color-success-shade: #00934f;
  --ion-color-success-tint: #1ab06b;

  /** warning **/

  --ion-color-warning: #FFCC00;
  --ion-color-warning-rgb: 255,
  204,
  0;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0,
  0,
  0;
  --ion-color-warning-shade: #e0b400;
  --ion-color-warning-tint: #ffd11a;

  /** danger **/

  --ion-color-danger: #FF3B30;
  --ion-color-danger-rgb: 255,
  59,
  48;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255,
  255,
  255;
  --ion-color-danger-shade: #e0342a;
  --ion-color-danger-tint: #ff4f45;

  /** dark **/

  --ion-color-dark: #1C1C1E;
  --ion-color-dark-rgb: 28,
  28,
  30;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255,
  255,
  255;
  --ion-color-dark-shade: #19191a;
  --ion-color-dark-tint: #333335;

  /** grey **/
  --ion-color-grey: #d3d6db;
  --ion-color-grey-rgb: 182,
  187,
  194;

  /** Alice blue **/
  --ion-color-alice-blue: #f2f7fc;
  --ion-color-alice-blue-rgb: 225,
  235,
  252;

  /** medium **/

  --ion-color-medium: #75767d;
  --ion-color-medium-rgb: 117,
  118,
  125;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255,
  255,
  255;
  --ion-color-medium-shade: #67686e;
  --ion-color-medium-tint: #83848a;

  /** light **/

  --ion-color-light: #e9e9e9;
  --ion-color-light-rgb: 233,
  233,
  233;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0,
  0,
  0;
  --ion-color-light-shade: #cdcdcd;
  --ion-color-light-tint: #ebebeb;

  /** very light **/

  --ion-color-very-light: #f5f5f5;
  --ion-color-very-light-rgb: 245,
  245,
  245;
  --ion-color-very-light-contrast: #000000;
  --ion-color-very-light-contrast-rgb: 0,
  0,
  0;
  --ion-color-very-light-shade: #d8d8d8;
  --ion-color-very-light-tint: #f6f6f6;


  /** Hylo Purple **/
  --ion-color-purple: #7030A0;
  --ion-color-purple-rgb: 112,
  48,
  160;
  --ion-color-purple-contrast: #ffffff;
  --ion-color-purple-contrast-rgb: 255,
  255,
  255;
  --ion-color-purple-shade: #632a8d;
  --ion-color-purple-tint: #7e45aa;

  /** Hylo Mustard **/

  --ion-color-mustard: #F6C645;
  --ion-color-mustard-rgb: 246,
  198,
  69;
  --ion-color-mustard-contrast: #000000;
  --ion-color-mustard-contrast-rgb: 0,
  0,
  0;
  --ion-color-mustard-shade: #d8ae3d;
  --ion-color-mustard-tint: #f7cc58;

  /** White **/
  --ion-color-white: #ffffff;
  --ion-color-white-rgb: 255,
  255,
  255;
  --ion-color-white-contrast: #000000;
  --ion-color-white-contrast-rgb: 0,
  0,
  0;
  --ion-color-white-shade: #e0e0e0;
  --ion-color-white-tint: #ffffff;

  /** Black **/
  --ion-color-black: #000000;
  --ion-color-black-rgb: 0,
  0,
  0;
  --ion-color-black-contrast: #ffffff;
  --ion-color-black-contrast-rgb: 255,
  255,
  255;
  --ion-color-black-shade: #000000;
  --ion-color-black-tint: #1a1a1a;

  /** Blue **/
  --ion-color-blue: #0073b7;
  --ion-color-blue-rgb: 0,
  115,
  183;
  --ion-color-blue-contrast: #ffffff;
  --ion-color-blue-contrast-rgb: 255,
  255,
  255;
  --ion-color-blue-shade: #0065a1;
  --ion-color-blue-tint: #1a81be;

  /** facebook **/
  --ion-color-facebook: #3b5998;
  --ion-color-facebook-rgb: 59,
  89,
  152;
  --ion-color-facebook-contrast: #ffffff;
  --ion-color-facebook-contrast-rgb: 255,
  255,
  255;
  --ion-color-facebook-shade: #344e86;
  --ion-color-facebook-tint: #4f6aa2;

  /** indigo **/
  --ion-color-indigo: #3d0f9f;
  --ion-color-indigo-rgb: 61,
  15,
  159;
  --ion-color-indigo-contrast: #ffffff;
  --ion-color-indigo-contrast-rgb: 255,
  255,
  255;
  --ion-color-indigo-shade: #360d8c;
  --ion-color-indigo-tint: #5027a9;


  /** Pending Badges **/

  --ion-color-pending: #ebeaf6;
  --ion-color-pending-rgb: 235,
  234,
  246;
  --ion-color-pending-contrast: #362da2;
  --ion-color-pending-contrast-rgb: 54,
  45,
  162;
  --ion-color-pending-shade: #cfced8;
  --ion-color-pending-tint: #edecf7;

  /** Partial Paid Badges **/

  --ion-color-partial: #fff5cc;
  --ion-color-partial-rgb: 255,
  245,
  204;
  --ion-color-partial-contrast: #000000;
  --ion-color-partial-contrast-rgb: 0,
  0,
  0;
  --ion-color-partial-shade: #e0d8b4;
  --ion-color-partial-tint: #fff6d1;

  /** Paid Badges **/

  --ion-color-paid: #e6f6ef;
  --ion-color-paid-rgb: 230,
  246,
  239;
  --ion-color-paid-contrast: #00a75a;
  --ion-color-paid-contrast-rgb: 5,
  150,
  105;
  --ion-color-paid-shade: #cad8d2;
  --ion-color-paid-tint: #e9f7f1;

  /** Failed Badges **/

  --ion-color-failed: #fce9e9;
  --ion-color-failed-rgb: 252,
  233,
  233;
  --ion-color-failed-contrast: #dc2626;
  --ion-color-failed-contrast-rgb: 220,
  38,
  38;
  --ion-color-failed-shade: #decdcd;
  --ion-color-failed-tint: #fcebeb;

  /** Draft Badges **/

  --ion-color-draft: #e3e4e5;
  --ion-color-draft-rgb: 227,
  228,
  229;
  --ion-color-draft-contrast: #000000;
  --ion-color-draft-contrast-rgb: 0,
  0,
  0;
  --ion-color-draft-shade: #c8c9ca;
  --ion-color-draft-tint: #e6e7e8;

  /** Page Background **/
  --ion-color-page-background: #ebedf0;
  --ion-color-page-background-rgb: 235,
  237,
  240;
  --ion-color-page-background-contrast: #000000;
  --ion-color-page-background-contrast-rgb: 0,
  0,
  0;
  --ion-color-page-background-shade: #dfdfdf;
  --ion-color-page-background-tint: #fdfdfd;

  --ion-color-minty-green: #8AAE92;
  --ion-color-minty-green-rgb: 138,
  174,
  146;
  --ion-color-minty-green-contrast: #000000;
  --ion-color-minty-green-contrast-rgb: 0,
  0,
  0;
  --ion-color-minty-green-shade: #799980;
  --ion-color-minty-green-tint: #96b69d;

  --ion-color-minty-green-800: #e8efe9;
  --ion-color-minty-green-800-rgb: 232,
  239,
  233;
  --ion-color-minty-green-800-contrast: #000000;
  --ion-color-minty-green-800-contrast-rgb: 0,
  0,
  0;
  --ion-color-minty-green-800-shade: #ccd2cd;
  --ion-color-minty-green-800-tint: #eaf1eb;

  --ion-color-input-box: #f9f9f9;
  --ion-color-input-box-rgb: 249,
  249,
  249;
  --ion-color-input-box-contrast: #000000;
  --ion-color-input-box-contrast-rgb: 0,
  0,
  0;
  --ion-color-input-box-shade: #dbdbdb;
  --ion-color-input-box-tint: #fafafa;

}

.notification-popover {
  --width: 290px !important;
  overflow-y: auto;
}

.inverted-icon {
  -webkit-filter: brightness(0) invert(1);
  /* Safari 6.0 - 9.0 */
  filter: brightness(0) invert(1);
}


@font-face {

  font-family: "hylobiz-global-font-medium";
  src: url("../assets/fonts/Inter/Inter-VariableFont_slnt,wght.ttf") format('truetype');
  font-weight: 500;
  font-style: normal;

}

@font-face {

  font-family: "hylobiz-global-font-regular";
  src: url("../assets/fonts/Inter/Inter-VariableFont_slnt,wght.ttf") format('truetype');
  font-weight: 300;
  font-style: normal;

}

@font-face {

  font-family: "hylobiz-global-font-light";
  src: url("../assets/fonts/Inter/Inter-VariableFont_slnt,wght.ttf") format('truetype');
  font-weight: 300;
  font-style: normal;

}

@font-face {

  font-family: "hylobiz-global-font-extra-light";
  src: url("../assets/fonts/Inter/Inter-VariableFont_slnt,wght.ttf") format('truetype');
  font-weight: 200;
  font-style: normal;

}

@font-face {

  font-family: "hylobiz-global-font-bold";
  src: url("../assets/fonts/Inter/Inter-VariableFont_slnt,wght.ttf") format('truetype');
  font-weight: 500;
  font-style: normal;

}

@font-face {

  font-family: "hylobiz-global-font-extra-bold";
  src: url("../assets/fonts/Inter/Inter-VariableFont_slnt,wght.ttf") format('truetype');
  font-weight: 600;
  font-style: normal;

}

@font-face {

  font-family: "hylobiz-global-font-super-bold";
  src: url("../assets/fonts/Inter/Inter-VariableFont_slnt,wght.ttf") format('truetype');
  font-weight: 800;
  font-style: normal;

}

@font-face {

  font-family: "hylobiz-global-font-semi-bold";
  src: url("../assets/fonts/Inter/Inter-VariableFont_slnt,wght.ttf") format('truetype');
  font-weight: 400;
  font-style: normal;

}


ion-item {
  --background: #ffffff !important;
  --ion-item-background-activated: transparent !important;
  --background-focused-opacity: 0 !important;
  --padding-end: 0px;
  --inner-padding-top: 0rem !important;
  --inner-padding-bottom: 0rem !important;
  --inner-padding-start: 0.25rem !important;
  --inner-padding-end: 0.25rem !important;
  --detail-icon-color: var(--ion-color-medium) !important;
  --detail-icon-opacity: 1 !important;
}

ion-item.item-has-focus>ion-label {
  color: var(--ion-color-dark) !important;
  font-weight: 500 !important;
  font-size: 16px !important;
}

ion-item.item-interactive.ion-valid {
  --highlight-background: var(--ion-color-primary) !important;
}

.modal {
  &::part(content) {
    position: absolute;
    display: block;
    --width: 480px;
    --height: 530px;
    --border-radius: 10px 10px 10px 10px;
    --box-shadow: 0px -0.5px 2px 1px rgba(0, 0, 0, 0.1);
  }

  .modal-shadow {
    position: absolute;
    display: block;
    top: calc(100% - (450px));
    --height: 450px;
  }
}


.listModal {
  &::part(content) {
    position: absolute;
    display: block;
    --width: 600px;
    --height: 600px;
    --border-radius: 10px 10px 10px 10px;
    --box-shadow: 0px -0.5px 2px 1px rgba(0, 0, 0, 0.1);
  }

  .modal-shadow {
    position: absolute;
    display: block;
    top: calc(100% - (450px));
    --height: 450px;
  }
}

.rejectModal {
  &::part(content) {
    position: absolute;
    display: block;
    --width: 600px;
    --height: 300px;
    --border-radius: 10px 10px 10px 10px;
    --box-shadow: 0px -0.5px 2px 1px rgba(0, 0, 0, 0.1);
  }

  .modal-shadow {
    position: absolute;
    display: block;
    top: calc(100% - (450px));
    --height: 450px;
  }
}

.custom-toast-message {
  --border-radius: 6px !important;
  --padding: 8px 16px !important;
  --width: 90% !important;
  margin-right: 0px !important;
  --min-height: 70px !important;
  font-size: 16px !important;
}

.b-r-10 {
  border-radius: 10px !important;
}

.border-light-color {
  border-color: var(--ion-color-light) !important
}

.border-primary-color {
  border-color: var(--ion-color-primary) !important;
}

.border-top-right-radius-dt {
  border-top-right-radius: 10px;
}

.border-top-left-radius-dt {
  border-top-left-radius: 10px;
}

.border-light-color {
  border-color: var(--ion-color-light) !important
}

.border-top {
  border-top: 0.5px solid var(--ion-color-medium);
}

.b-r-4 {
  border-radius: 4px !important;
}

.b-r-6 {
  border-radius: 6px !important;
}

.no-border {
  border: 0 !important
}

.add-bg-on-hover:hover {
  background: var(--ion-color-light);
}

.card {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #f5f8fd;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, .125);
  border-radius: 10px !important;
}

.card>hr {
  margin-right: 0;
  margin-left: 0
}

.card>.list-group:first-child .list-group-item:first-child {
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem
}

.card>.list-group:last-child .list-group-item:last-child {
  border-bottom-right-radius: .25rem;
  border-bottom-left-radius: .25rem
}

.card-body {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1rem
}

.card-title {
  margin-bottom: .75rem
}

.card-subtitle {
  margin-top: -.375rem;
  margin-bottom: 0
}

.card-text:last-child {
  margin-bottom: 0
}

.card-link:hover {
  text-decoration: none
}

.card-link+.card-link {
  margin-left: 1.25rem
}

.card-header {
  padding: 0.45rem;
  margin-bottom: 0;
  background-color: rgb(245, 245, 254, 0.5);
  border-bottom: 1px solid rgba(0, 0, 0, .125);
}

.card-header:first-child {
  border-radius: 0;
}

.card-header+.list-group .list-group-item:first-child {
  border-top: 0
}

.card-footer {
  padding: .75rem 1.25rem;
  background-color: rgb(245, 245, 254, 0.5);
  border: 1px solid rgba(0, 0, 0, .125);
}

.card-footer:last-child {
  border-radius: 0;
}

.bg-transparent {
  background: transparent !important;
  background-color: transparent !important;
  background-color: none !important;
}

.icon-outer {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 10px;
  padding: 8px;
  border: 1px solid var(--ion-color-light-shade);
}

.text-underline {
  text-decoration: underline;
}

.fs-32 {
  font-size: 32px !important;
}

.fs-28 {
  font-size: 28px !important;
}

.fs-24 {
  font-size: 24px !important;
}

.fs-22 {
  font-size: 22px !important;
}

.fs-18 {
  font-size: 18px !important;
}

.fs-20 {
  font-size: 20px !important;
}

.fs-16 {
  font-size: 16px !important;
}

.fs-14 {
  font-size: 14px !important;
}

.fs-12 {
  font-size: 12px !important;
}

.fs-10 {
  font-size: 10px !important;
}

.fs-8 {
  font-size: 8px !important;
}

ion-button {
  height: 38px;
}

.cursor-pointer {
  cursor: pointer;
}

.dt-web {
  @apply hidden lg:block xl:block mr-2 ml-2 border-2 b-r-10 bg-[var(--ion-color-white)] border-light-color
}


.dt-header {
  @apply bg-[var(--ion-color-primary)] text-center h-14 items-center sticky top-0 z-10 p-2 border-2 border-primary-color font-extrabold border-top-right-radius-dt border-top-left-radius-dt
}


.dt-body {
  @apply bg-[var(--ion-color-white)] text-center items-center border-b p-2 hover:bg-[var(--ion-color-fusion-theme)]
}


.dt-footer {
  @apply bg-[var(--ion-color-white)] flex text-center h-16 flex-row items-center justify-between bottom-0 z-10 px-3 sticky border-2 border-light-color
}


.dt-active-page {
  @apply flex items-center justify-center w-10 h-10 ml-1 mr-1 bg-[var(--ion-color-primary)] b-r-4
}

.dt-inative-page {
  @apply flex items-center justify-center w-10 h-10 ml-1 mr-1 bg-[var(--ion-color-white)] b-r-4
}

.dt-inactive-text {
  @apply font-bold fs-16 text-[var(--ion-color-dark)]
}

.dt-active-text {
  @apply font-bold fs-16 text-[var(--ion-color-white)]
}

.pagination-button {
  @apply flex items-center justify-center w-10 h-10 ml-1 mr-1 bg-[var(--ion-color-white)] b-r-4
}

.dt-active-page:hover {
  @apply bg-[var(--ion-color-primary-tint)]
}

.dt-inative-page:hover {
  @apply bg-[var(--ion-color-light)]
}

.pagination-button:hover {
  @apply bg-[var(--ion-color-light)]
}

.dt-border-radius {
  border-radius: 10px;
}

ion-loading.custom-loading {
  --background: none !important;
  --spinner-color: #fff !important;
  --background: #000 !important;
  --backdrop-opacity: 0.40 !important;

  .loading-wrapper {
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
    --background: transparent !important;
    --background: none !important;
  }

}

.custom-css-alert-box {
  --backdrop-opacity: 0.8;
  --max-width: 50%;
  font-family: "hylobiz-global-font-bold", sans-serif !important;
}

.text-red {
  color: var(--ion-color-danger) !important
}

.search-box {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.filter-2-container {
  height: 70px;
  width: 100%;
  background-color: var(--ion-color-white);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}

.formClass {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.dt-border-radius {
  border-radius: 10px;
}

#drop-down {
  background-color: var(--ion-color-pending);
  display: flex;
  border-radius: 32px;
  padding: 3px !important;
  height: 40px;
}

.login-box .native-input{
  height: 100%;
  padding: 10px;
  border-radius: 10px;

}
.login-email .input-wrapper{
  width: 91%;
}
.login-pass .input-wrapper{
  margin-right: 5px;
}

#drop-down-label {
  opacity: 0.5;
}

#dtinput {
  border: none;
  outline: none;
  width: 20%;
  height: 50px;
}

.bg-light-background {
  --inner-padding-start: 0 !important;
  --inner-padding-end: 0 !important;
  // background: #f9f9fe;
  background: var(--ion-color-very-light);
  border-bottom: 2px solid transparent;
  border-radius: 0px;
}

.bg-verylight-background {
  --inner-padding-start: 0 !important;
  --inner-padding-end: 0 !important;
  // background: #f9f9fe;
  background: var(--ion-color-light);
  border-bottom: 2px solid transparent;
  border-radius: 0px;
}

.login-box {
  position: relative;
  box-shadow: 0 5px 10px rgba(154, 160, 185, .05), 0 15px 40px rgba(166, 173, 201, .2);
}